import React, { useEffect, useState } from "react";
import { ContentsTypeRes } from "src/Models/ResponseModels/Contents";
import loadPage from "../_HOC/loadPage";
import history from 'src/history';
import useCurrentWidth from "../_hooks/useCurrentWidth";
import axiosInstance from "src/config/axios";
import MyPersonalisedRosaKneePlayerVideo from "./MyPersonalisedRosaKneePlayerVideo";
import MyPersonalisedRosaKneeEpisode from "./MyPersonalisedRosaKneeEpisode";
import Button from "../_UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPlay, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { scrollToElementWithId } from "src/config/functions";


interface IProps {
    response: any,
}

const MyPersonalisedRosaKnee = ({ response }: IProps) => {
    const [contents, setContents] = useState<ContentsTypeRes.IContent[]>([]);
    const [contentsTeaser, setContentsTeaser] = useState<ContentsTypeRes.IContent[]>([]);
    const [selectedContents, setSelectedContents] = useState<ContentsTypeRes.IContent>();
    const [showPlayer, setShowPlayer] = useState<boolean[]>([false, false]);
    const [showThumbnail, setShowThumbnail] = useState<boolean>(true);

    useEffect(() => {
        if (response) {
            let contents: ContentsTypeRes.IContent[] = response.data.data.collection;

            let _contentsTeaser = contents.filter(x => x.additionalField04 === "Teaser");
            console.log('_contentsTeaser', _contentsTeaser)
            setSelectedContents(_contentsTeaser[0]);

            setContents(contents);

            let queryStringSanitized = window.location.search
            queryStringSanitized = queryStringSanitized.replace('?', '')
            if (queryStringSanitized.length > 0) {
                let contentFound = false;
                contents.map((content) => {
                    if (content.friendlyURL === queryStringSanitized) {
                        setSelectedContents(content);
                        contentFound = true;
                        // Save log for preselected webinar
                        axiosInstance.get('Contents/' + content.contentID + '/SaveLog');
                    }
                })
                if (!contentFound) {
                    setSelectedContents(_contentsTeaser[0]);
                    history.push({
                        pathname: '/my-personalized-rosa-knee-series',
                        search: ''
                    })
                }
            } else {
                setSelectedContents(_contentsTeaser[0]);
            }
        }
    }, [response])

    const handleChangeTab = (content: ContentsTypeRes.IContent) => {
        console.log('handleChangeTab');
        setShowThumbnail(true);
        setSelectedContents(content);
        scrollToElementWithId("player-video", -200);
        history.push({
            pathname: '/my-personalized-rosa-knee-series',
            search: '?' + content.friendlyURL
        })
        axiosInstance.get('Contents/' + content.contentID + '/SaveLog');
    }

    return (
        <section className="my-personalized">
            <div className="my-personalized-background">
                <div className={"my-personalized-background-upper " + (!!selectedContents ? "episodes" : "")}>
                    <div className="my-personalized-header">
                        <img className="my-personalized-logo" src="/assets/images/my-personalised/logo.png" />
                        {/* <div className="my-personalized-next-episode ml-auto mt-3 d-flex justify-content-center flex-column">
                            <MyPersonalisedRosaKneeNextEpisode />
                        </div> */}
                        <div className="my-personalized-header-buttons-wrapper">
                            <div className="my-personalized-header-buttons">
                                {/* <Button
                                    withClass={["small"]}
                                    classes={"my-personalized-teaser__button ml-auto mt-3"}
                                    clicked={() => { window.open('/assets/docs/pages/my-personalized-prorgam-whitepaper-2021.pdf') }}>
                                    <FontAwesomeIcon className="mr-2" icon={faFile} size="lg" />
                                    Rapid Recovery Program
                                </Button> */}
                                {/*
                                    !selectedContents.some(x => x.additionalField04 === "Teaser") &&
                                    <Button
                                        withClass={["small"]}
                                        classes={"my-personalized-teaser__button ml-auto mt-3"}
                                        clicked={() => resetTeaser()}
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faPlayCircle} size="lg" />
                                        Watch the Teaser
                                    </Button>
                                */}
                            </div>
                        </div>
                    </div>
                    <div id="mt-5 player-video">
                        {!!showThumbnail ?
                            <div className="container">
                                <div className="my-personalized-wrapper-video"
                                style={{ marginLeft: "auto" }}
                                    onClick={() => {
                                        axiosInstance.get('Contents/' + selectedContents?.contentID + '/SaveLog');
                                        setShowThumbnail(false);
                                    }}>
                                    <h3 className="my-personalized-video-title u-font-size-12 mb-3 text-center">{selectedContents?.contentTitle}</h3>
                                    <div className="container-video my-personalized-video" >
                                        <img className="img-fluid" src={selectedContents?.imageURL || ''} />
                                        <div className="thumbnail-play">
                                            <FontAwesomeIcon icon={faPlay} color="white" size="2x" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <MyPersonalisedRosaKneePlayerVideo
                                showShareLink={false}
                                contentsList={selectedContents ? [selectedContents] : []}
                            />}
                    </div>
                </div>
                <div className="my-personalized-background__gradient">

                    <div className="my-personalized-episode row justify-content-center">
                        {
                            contents.map((content) => {
                                if (content.contentTitle !== 'Teaser') {
                                    return (
                                        <div className="col-xl-4 col-lg-6 col-sm-12" key={content.contentID} onClick={(e) => handleChangeTab(content)} >
                                            <MyPersonalisedRosaKneeEpisode
                                                ID={content.contentID}
                                                title={content.contentTitle}
                                                subtitle={content.contentSubTitle}
                                                image={content.imageURL}
                                                iframe={content.contentDescription}
                                                firstFaculty={content.additionalField02}
                                                secondFaculty={content.additionalField03}
                                            />
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                </div>
            </div>
        </section >
    )
}

export default loadPage(
    MyPersonalisedRosaKnee,
    {
        url: "Contents/Categories/my-personalized-rosa-knee-series/Contents",
        // url: "Contents/Categories/personalized-knee-care/Contents",
        method: "GET",
        data: {}
    }
);