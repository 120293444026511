import React, { useEffect, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import { ContentsTypeRes } from "src/Models/ResponseModels/Contents";
import ShareLinkModal from "../ShareLinkModal/ShareLinkModal";
import Button from '../_UI/Button/Button';



interface IProps {
    contentsList: ContentsTypeRes.IContent[],
    showShareLink?: boolean,
}

const MyPersonalisedRosaKneePlayerVideo = ({ contentsList, showShareLink = true }: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [innerTitle, setInnerTitle] = useState("");
    const [innerIframe, setInnerIframe] = useState("");
    const [innerFriendlyUrl, setInnerFriendlyUrl] = useState("");
    const [showButtons, setShowButtons] = useState(false);

    const handleSurveyButtonClick = () => {
        window.open(`${window.location.origin}/webinar-survey/${innerFriendlyUrl}`, "_blank")
    }

    useEffect(() => {
        if (contentsList && contentsList.length > 0) {
            setSelectedTab(0);
            setInnerTitle(contentsList[0].contentTitle);
            setInnerIframe(contentsList[0].contentDescription || "");
            setInnerFriendlyUrl(contentsList[0].friendlyURL);
            // setShowButtons(contentsList[0].additionalField04 !== "Teaser");
        }
    }, [contentsList]);

    return (
        <>
            <div className="container text-center">
                <div className="my-personalized-wrapper-video">
                    {contentsList.length === 1 && <h3 className="my-personalized-video-title u-font-size-12 mb-3">{innerTitle}</h3>}
                    <div className="container-video my-personalized-video">
                        {ReactHtmlParser(innerIframe)}
                    </div>
                    {/* <div className="my-personalized-shadow" /> */}

                </div>
                <div className="my-personalized-buttons mt-3">
                    {
                        showShareLink &&
                        <Button
                            icon="faShare"
                            withClass={["small"]}
                            classes={"my-personalized-buttons__share"}
                            clicked={() => setIsModalOpen(!isModalOpen)}>
                            Share Link
                        </Button>
                    }
                    {
                        showButtons &&
                        <Button
                            withClass={["small"]}
                            classes={"my-personalized-buttons__share"}
                            clicked={() => handleSurveyButtonClick()}>
                            Feedback Survey
                        </Button>
                    }
                </div>
            </div>



            <ShareLinkModal
                isModalVisible={isModalOpen}
                handleModal={() => setIsModalOpen(!isModalOpen)}
                title={innerTitle}
            >
            </ShareLinkModal>
        </>
    )
}

export default MyPersonalisedRosaKneePlayerVideo