import moment from 'moment';

export const categoryFiles = [
    {
        CategoryID: 6,
        type: 'PROGRAM',
        folder: 'assets/pdfview/totally-hip-2017',
        baseFileName: 'Programme International Hip Meeting 2017_Pagina_',
        pages: 21,
        fileExt: 'jpg'
    },
    {
        CategoryID: 7,
        type: 'PROGRAM',
        folder: 'assets/pdfview/Programma_Atene',
        baseFileName: 'Programme Trauma Talk Symposium 8-9 June 2017-V16_Pagina_0',
        pages: 8,
        fileExt: 'jpg'
    },
    {
        CategoryID: 8,
        type: 'PROGRAM',
        folder: 'assets/pdfview/persona-partial-knee-program',
        baseFileName: 'persona-partial-knee-launch-symposium-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 10,
        type: 'PROGRAM',
        folder: 'assets/pdfview/interaction-meeting-revision-knee-2017-program',
        baseFileName: 'interaction-meeting-revision-knee-2017-program-',
        pages: 8,
        fileExt: 'jpg'
    },
    {
        CategoryID: 11,
        type: 'PROGRAM',
        folder: 'assets/pdfview/foot-and-ankle-symposium-2017-program',
        baseFileName: 'foot-and-ankle-symposium-2017-program-',
        pages: 7,
        fileExt: 'jpg'
    },
    {
        CategoryID: 11,
        type: 'PROGRAM',
        folder: 'assets/pdfview/foot-and-ankle-symposium-2017-program',
        baseFileName: 'foot-and-ankle-symposium-2017-program-',
        pages: 7,
        fileExt: 'jpg'
    },
    {
        CategoryID: 13,
        type: 'PROGRAM',
        folder: 'assets/pdfview/sports-medicine-symposium-17-program',
        baseFileName: 'sports-medicine-symposium-17-program-',
        pages: 8,
        fileExt: 'jpg'
    },
    {
        CategoryID: 15,
        type: 'PROGRAM',
        folder: 'assets/pdfview/interaction-meeting-pji-02-2018-program',
        baseFileName: 'interaction-meeting-pji-02-2018-program-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 22,
        type: 'PROGRAM',
        folder: 'assets/pdfview/interaction-meeting-trauma-04-18-program',
        baseFileName: 'interaction-meeting-trauma-04-18-program-',
        pages: 5,
        fileExt: 'jpg'
    },
    {
        CategoryID: 24,
        type: 'PROGRAM',
        folder: 'assets/pdfview/complex-knee-09-18-program',
        baseFileName: 'complex-knee-09-18-program-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 26,
        type: 'PROGRAM',
        folder: 'assets/pdfview/complex-spine-11-18-program',
        baseFileName: 'complex-spine-11-18-program-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 28,
        type: 'PROGRAM',
        folder: 'assets/pdfview/hip-revision-11-18-program',
        baseFileName: 'hip-revision-11-18-program-',
        pages: 7,
        fileExt: 'jpg'
    },
    {
        CategoryID: 29,
        type: 'PROGRAM',
        folder: 'assets/pdfview/periprosthetic-joint-infection-02-19-program',
        baseFileName: 'periprosthetic-joint-infection-02-19-program-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 30,
        type: 'PROGRAM',
        folder: 'assets/pdfview/shoulder-solutions-symposium-05-19-program',
        baseFileName: 'shoulder-solutions-symposium-05-19-program-',
        pages: 8,
        fileExt: 'jpg'
    },
    {
        CategoryID: 30,
        type: 'VM',
        folder: 'assets/pdfview/shoulder-solutions-symposium-05-19-vm-info',
        baseFileName: '0',
        pages: 5,
        fileExt: 'png'
    },
    {
        CategoryID: 31,
        type: 'PROGRAM',
        folder: 'assets/pdfview/interaction-meeting-trauma-04-19-program',
        baseFileName: 'interaction-meeting-trauma-04-19-program-',
        pages: 5,
        fileExt: 'jpg'
    },
    {
        CategoryID: 33,
        type: 'PROGRAM',
        folder: 'assets/pdfview/interaction-meeting-spine-09-19-program',
        baseFileName: 'interaction-meeting-spine-09-19-program-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 35,
        type: 'PROGRAM',
        folder: 'assets/pdfview/beyond-implants-02-20-program',
        baseFileName: 'beyond-implants-02-20-program-',
        pages: 6,
        fileExt: 'jpg'
    },
    {
        CategoryID: 74,
        type: 'PROGRAM',
        folder: 'assets/pdfview/avenir-complete-first-experience',
        baseFileName: 'webinar-avenir-complete',
        pages: 1,
        fileExt: 'pdf'
    }
];

export const homeCarouselImages = [
    {
        ID: 0,
        type: 'IMAGE',
        folder: 'assets/images/my-personalised/',
        fileNameBig: 'banner-1920x460',
        fileNameMedium: 'banner-1024x350',
        fileNameSmall: 'banner-1024x350',
        fileExt: 'jpg',
        alt: 'My Personalised ROSA Knee',
        link: '/my-personalized-rosa-knee-series'
    },
    {
        ID: 1,
        type: 'IMAGE',
        folder: 'assets/images/advancedpersonalisationtotalhiparthroplasty2024/',
        fileNameBig: 'banner_1920x460',
        fileNameMedium: 'banner_1024x350',
        fileNameSmall: 'banner_1024x350',
        fileExt: 'jpg',
        alt: "Advanced personalisation total hip arthroplasty 2024",
        link: '/events/advanced-personalisation-total-hip-arthroplasty-2024'
    },
    {
        ID: 2,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-what-should-we-do-1920-460',
        fileNameMedium: 'banner-what-should-we-do-1024-350',
        fileNameSmall: 'banner-what-should-we-do-1024-350',
        fileExt: 'jpg',
        alt: "What Should We Do?",
        link: '/events/shoulder-symposium-what-should-we-do'
    },
    {
        ID: 3,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-talk-about-infection-1920-460',
        fileNameMedium: 'banner-talk-about-infection-1024-350',
        fileNameSmall: 'banner-talk-about-infection-1024-350',
        fileExt: 'jpg',
        alt: "Let's talk about Infection",
        link: '/events/lets-talk-about-infection'
    },
    {
        ID: 4,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-shoulder-and-clavicle-treatment-solutions-1920-460',
        fileNameMedium: 'banner-shoulder-and-clavicle-treatment-solutions-1024-350',
        fileNameSmall: 'banner-shoulder-and-clavicle-treatment-solutions-1024-350',
        fileExt: 'jpg',
        alt: "Masterclass Shoulder and Clavicle Treatment Solutions",
        link: '/events/shoulder-clavicle-treatment-solutions'
    },
    {
        ID: 5,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-problem-solved-1920x460',
        fileNameMedium: 'banner-problem-solved-1024x350',
        fileNameSmall: 'banner-problem-solved-1024x350',
        fileExt: 'jpg',
        alt: 'Problem Solved',
        link: '/events/problem-solved-2022'
    },
    {
        ID: 6,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-rapid-recovery-1920-460',
        fileNameMedium: 'banner-rapid-recovery-1024-350',
        fileNameSmall: 'banner-rapid-recovery-1024-350',
        fileExt: 'jpg',
        alt: 'Rapid recovery series',
        link: '/rapid-recovery-series'
    },
    {
        ID: 7,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-knee-1920-460',
        fileNameMedium: 'banner-knee-1024-350',
        fileNameSmall: 'banner-knee-1024-350',
        fileExt: 'jpg',
        alt: 'Personalized Knee Care',
        link: '/personalized-knee-care'
    },
    {
        ID: 8,
        type: 'IMAGE',
        folder: 'assets/images/women-in-ortopedics',
        fileNameBig: 'women-in-orth-banner-1920x460',
        fileNameMedium: 'women-in-orth-banner-1024x350',
        fileNameSmall: 'women-in-orth-banner-1024x350',
        fileExt: 'jpg',
        alt: 'Women in Orthopedics Page',
        link: '/women-in-orthopedics'
    },
    {
        ID: 9,
        type: 'IMAGE',
        folder: 'assets/images/pji-series/',
        fileNameBig: 'banner-PJI-1920x460',
        fileNameMedium: 'banner-PJI-1024x350',
        fileNameSmall: 'banner-PJI-1024x350',
        fileExt: 'jpg',
        alt: 'PJI Series',
        link: '/pji-series/pji-series-diagnosis-3'
    },
    {
        ID: 10,
        type: 'IMAGE',
        folder: 'assets/images/home/',
        fileNameBig: 'banner-1920-460',
        fileNameMedium: 'banner-1024-350-mobile',
        fileNameSmall: 'banner-1024-350-mobile',
        fileExt: 'jpg',
        alt: 'Welcome Page',
        link: '/welcome'
    },
        
    
    
    // {women-in-orth-banner-1024x350.jpg
    //     ID: 19,
    //     type: 'IMAGE',
    //     folder: 'assets/images/on-demand-learning',
    //     fileNameBig: 'banner-on-demand-digital-learning-sessions-1920',
    //     fileNameMedium: 'banner-on-demand-digital-learning-sessions-1280',
    //     fileNameSmall: 'banner-on-demand-digital-learning-sessions-1280',
    //     fileExt: 'jpg',
    //     alt: 'On-Demand Digital Learning Sessions',
    //     link: '/on-demand-digital-learning-sessions'
    // },    
    // {
    //     ID: 18,
    //     type: 'IMAGE',
    //     folder: 'assets/images/on-air-surgery',
    //     fileNameBig: 'banner-or-1920',
    //     fileNameMedium: 'banner-or-1024',
    //     fileNameSmall: 'banner-or-1024',
    //     fileExt: 'jpg',
    //     alt: 'Patient outcomes in PKR Series',
    //     link: '/patient-outcomes-in-pkr'
    // },
    // {
    //     ID: 17,
    //     type: 'IMAGE',
    //     folder: 'assets/images/covid',
    //     fileNameBig: 'banner-covid-19-series-1920',
    //     fileNameMedium: 'banner-covid-19-series-1024',
    //     fileNameSmall: 'banner-covid-19-series-1024',
    //     fileExt: 'jpg',
    //     alt: 'Covid Series',
    //     link: '/elective-surgery-in-the-covid-19-era'
    // },
    // {
    //     ID: 6,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-robotics-1920',
    //     fileNameMedium: 'banner-robotics-1024',
    //     fileNameSmall: 'banner-robotics-1024',
    //     fileExt: 'jpg',
    //     alt: 'Robotics Series',
    //     link: '/robotics'
    // },
    // {
    //     ID: 7,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-emea-virtual-exhibition-1920',
    //     fileNameMedium: 'banner-emea-virtual-exhibition-1024',
    //     fileNameSmall: 'banner-emea-virtual-exhibition-1024',
    //     fileExt: 'jpg',
    //     alt: 'The Virtual Exhibition',
    //     link: '/the-virtual-exhibition-experience'
    // },
    // {
    //     ID: 16,
    //     type: 'IMAGE',
    //     folder: 'assets/images',
    //     fileNameBig: 'banner-case-forum-2021',
    //     fileNameMedium: 'banner-case-forum-2021',
    //     fileNameSmall: 'banner-case-forum-2021',
    //     fileExt: 'jpg',
    //     alt: 'Case Forum',
    //     link: '/case-forum-calendar'
    // },
    // {
    //     ID: 14,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'pji-banner-1920',
    //     fileNameMedium: 'pji-banner-1024',
    //     fileNameSmall: 'pji-banner-1024',
    //     fileExt: 'jpg',
    //     alt: 'Periprosthetic Joint Infection Series',
    //     link: pjiLink
    // },
    // {
    //     ID: 15,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-simple-solutions-1920',
    //     fileNameMedium: 'banner-simple-solutions-1024',
    //     fileNameSmall: 'banner-simple-solutions-1024',
    //     fileExt: 'jpg',
    //     alt: 'Simple Solutions',
    //     link: '/simple-solutions'
    // },
    // {
    //     ID: 1,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-beyond-implants-02-20-1920',
    //     fileNameMedium: 'banner-beyond-implants-02-20-1024',
    //     fileNameSmall: 'banner-beyond-implants-02-20-640',
    //     fileExt: 'jpg',
    //     alt: 'Beyond Implants',
    //     link: '/events/beyond-implants-02-2020'
    // },
    // {
    //     ID: 2,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-skills-in-pills-1920',
    //     fileNameMedium: 'banner-skills-in-pills-1024',
    //     fileNameSmall: 'banner-skills-in-pills-640',
    //     fileExt: 'jpg',
    //     alt: 'Skills in Pills',
    //     link: '/skills-in-pills' 
    // },
    // {
    //     ID: 3,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-e-learning-modules-1920',
    //     fileNameMedium: 'banner-e-learning-modules-1024',
    //     fileNameSmall: 'banner-e-learning-modules-640',
    //     fileExt: 'jpg',
    //     alt: 'e-learning',
    //     link: '/e-learning' 
    // },
    // {
    //     ID: 4,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-modern-cementing-tutorials-1920',
    //     fileNameMedium: 'banner-modern-cementing-tutorials-1024',
    //     fileNameSmall: 'banner-modern-cementing-tutorials-640',
    //     fileExt: 'jpg',
    //     alt: 'modern cementing tutorials',
    //     link: '/modern-cementing-tutorials'  
    // },
    // {
    //     ID: 5,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'banner-arthroplasty-tutorials-1920',
    //     fileNameMedium: 'banner-arthroplasty-tutorials-1024',
    //     fileNameSmall: 'banner-arthroplasty-tutorials-640',
    //     fileExt: 'jpg',
    //     alt: 'knee arthoroplasty tutorials',
    //     link: '/knee-arthoroplasty-tutorials'
    // },
    // {
    //     ID: 10,
    //     type: 'IMAGE',
    //     folder: 'assets/images/carousel',
    //     fileNameBig: 'zbi_fracture-1920',
    //     fileNameMedium: 'zbi_fracture-1024',
    //     fileNameSmall: 'zbi_fracture-640',
    //     fileExt: 'jpg',
    //     alt: 'fracture',
    //     link: 'https://www.zbinetworkemea.com/fracture/'
    // },
];

export const extrasItems = [
    {
        ID: 1,
        boxTitle: 'ABOUT US',
        boxImage: 'assets/images/extras/zbi-offer.jpg',
        linkTo: '/welcome'
    },
    // {
    //     ID: 2,
    //     boxTitle: 'Ebook TKA',
    //     boxImage: 'assets/images/extras/ebook-tka.jpg',
    //     linkTo: '/e-book'
    // },
    {
        ID: 2,
        boxTitle: '3D Anatomy',
        boxImage: 'assets/images/extras/3d-model-library.jpg',
        linkTo: '/3d-anatomy/model-library'
    },
    {
        ID: 3,
        boxTitle: 'Elective Surgery in the Covid-19 Era',
        boxImage: 'assets/images/extras/elective-surgery-in-the-covid-19.jpg',
        linkTo: '/elective-surgery-in-the-covid-19-era'
    },
    {
        ID: 4,
        boxTitle: 'Industry Links',
        boxImage: 'assets/images/extras/industry-links.jpg',
        linkTo: '/industry-links'
    },
    {
        ID: 5,
        boxTitle: 'Trabecular Metal technology',
        boxImage: 'assets/images/extras/trabecular-metal-technology.jpg',
        linkTo: '/trabecular-metal-tech'
    },
    {
        ID: 6,
        boxTitle: 'Clinical evidence,<br>Registries & GuideLines',
        boxImage: 'assets/images/extras/clinical-evidence.jpg',
        linkTo: '/clinical-evidence'
    },
];

export const professionalDevelopmentItems = [
    {
        ID: 1,
        boxTitle: 'Skills in Pills',
        boxImage: 'assets/images/professional-development/skills-in-pills.jpg',
        linkTo: '/skills-in-pills',
        isolateContentView: false
    },
    /* {
        ID: 2,
        boxTitle: '<span style="text-transform: lowercase;">e</span>LIBRARY',
        boxImage: 'assets/images/professional-development/e_library.jpg',
        linkTo: '/e-library',
        isolateContentView: false
    }, */
    {
        ID: 3,
        boxTitle: 'EssentiApp',
        boxImage: 'assets/images/professional-development/essenti-app.jpg',
        linkTo: '/essenti-app',
        isolateContentView: false
    },
    {
        ID: 4,
        boxTitle: 'Grants & Donations',
        boxImage: 'assets/images/professional-development/grants-and-donations.jpg',
        linkTo: '/grants-and-donations',
        isolateContentView: false
    },
    {
        ID: 5,
        boxTitle: 'Faculty Room',
        boxImage: 'assets/images/professional-development/faculty-room.jpg',
        linkTo: '/faculty-room',
        isolateContentView: true,
        tagID: Number(process.env.REACT_APP_FACULTY_ROOM_TAG)
    },
];

export const womenInOrthopedicsItems = [
    {
        ID: 1,
        boxTitle: 'PODCASTS AND PUBLICATIONS',
        boxImage: 'assets/images/women-in-ortopedics/publications.jpg',
        linkTo: '/podcasts-and-publications',
        mailTo: ''
    },
    {
        ID: 2,
        boxTitle: 'WEBINARS',
        boxImage: 'assets/images/women-in-ortopedics/webinar.jpg',
        linkTo: '/women-in-orthopedics/webinars/',
        mailTo: ''
    },
    {
        ID: 3,
        boxTitle: 'E-LIBRARY',
        boxImage: 'assets/images/women-in-ortopedics/library.jpg',
        linkTo: '/e-library',
        mailTo: ''
    },
    {
        ID: 4,
        boxTitle: 'RETURN TO WORK',
        boxImage: 'assets/images/women-in-ortopedics/return-work.jpg',
        linkTo: '/welcome?return-to-work',
        mailTo: ''
    },
    {
        ID: 5,
        boxTitle: 'EDUCATIONAL GRANTS & DONATIONS',
        boxImage: 'assets/images/women-in-ortopedics/grants.jpg',
        linkTo: '/grants-and-donations',
        mailTo: ''
    },
    {
        ID: 6,
        boxTitle: 'CONTACT US',
        boxImage: 'assets/images/women-in-ortopedics/contact.jpg',
        linkTo: '',
        mailTo: 'wio@zimmerbiomet.com'
    },
];

export const tagsOptions = [
    { tagID: 0, tagFriendlyUrl: 'all', tagDescription: 'All' },
    { tagID: 1604, tagFriendlyUrl: 'hip', tagDescription: 'Hip' },
    { tagID: 1600, tagFriendlyUrl: 'knee', tagDescription: 'Knee' },
    { tagID: 1605, tagFriendlyUrl: 'shoulder', tagDescription: 'Shoulder' },
    { tagID: 1602, tagFriendlyUrl: 'elbow', tagDescription: 'Elbow' },
    { tagID: 1607, tagFriendlyUrl: 'hand-and-wrist', tagDescription: 'Hand & Wrist' },
    { tagID: 1603, tagFriendlyUrl: 'foot-and-ankle', tagDescription: 'Foot & Ankle' },
    { tagID: 1601, tagFriendlyUrl: 'cmf-and-thoracic', tagDescription: 'CMF & Thoracic' },
    // { tagID: 1606, tagFriendlyUrl: 'spine', tagDescription: 'Spine' },
    { tagID: 1612, tagFriendlyUrl: 'trauma', tagDescription: 'Trauma' },
    { tagID: 1608, tagFriendlyUrl: 'arthroscopy-and-biologics', tagDescription: 'Arthroscopy & Biologics' },
    { tagID: 1609, tagFriendlyUrl: 'cement-and-infection', tagDescription: 'Cement & Infection' },
    { tagID: 1611, tagFriendlyUrl: 'robotics', tagDescription: 'Robotics' },
    { tagID: 1610, tagFriendlyUrl: 'patient-pathway-optimization', tagDescription: 'Patient Pathway Optimization' },
    { tagID: 1616, tagFriendlyUrl: 'residents', tagDescription: 'Residents' },
    { tagID: 1618, tagFriendlyUrl: 'women-in-orthopedics', tagDescription: 'Women in Orthopedics' },
];

export const agendaItems = [
    /* THIRD PARTY CONGRESS (IDs: 1 - 100) */
    {
        ID: 1,
        type: 'content',
        date: new Date('2021-01-07'),
        endDate: new Date('2021-01-09'),
        time: '',
        boxTitle: 'Berlin International Shoulder Course',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'Berlin International Shoulder Course',
        detailSubtitle: 'Third Party Congress',
        detailLink: '',
        externalLink: 'https://live.berlin-shoulder-course.com/workshops/zimmer-biomet',
        detailImage: 'third-party-congress-2021.jpg',
        sideEventString: 'Check out our Side Event',
        tags: 'shoulder;arthroscopy-and-biologics',
    },
    {
        ID: 2,
        type: 'content',
        date: new Date('2021-04-22'),
        endDate: new Date('2021-04-24'),
        time: '',
        boxTitle: 'WAC Congress',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'WAC Congress',
        detailSubtitle: 'Check out our Side Event',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        sideEventString: 'Check out our Side Event',
        tags: 'knee;patient-pathway-optimization',
    },
    {
        ID: 3,
        type: 'content',
        date: new Date('2021-05-11'),
        endDate: new Date('2021-05-15'),
        time: '',
        boxTitle: 'ESSKA Congress',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'ESSKA Congress',
        detailSubtitle: 'Third Party Congress',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        sideEventString: 'Check out our Side Events',
        tags: 'arthroscopy-and-biologics;shoulder;knee',
    },
    {
        ID: 4,
        type: 'content',
        date: new Date('2021-06-16'),
        endDate: new Date('2021-06-19'),
        time: '',
        boxTitle: 'FESSH',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'FESSH',
        detailSubtitle: 'Third Party Congress',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        sideEventString: 'Check out our Side Event',
        tags: 'trauma;hand-and-wrist',
    },
    {
        ID: 5,
        type: 'content',
        date: new Date('2021-06-30'),
        endDate: new Date('2021-07-02'),
        time: '',
        boxTitle: 'Virtual EFORT Congress (VEC)',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'Virtual EFORT Congress (VEC)',
        detailSubtitle: 'Third Party Congress',
        sideEventString: 'Check out our Side Events',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        tags: 'trauma;hip;cement-and-infection',
    },
    {
        ID: 6,
        type: 'content',
        date: new Date('2021-07-09'),
        endDate: new Date('2021-07-09'),
        time: '',
        boxTitle: 'Global Shoulder Arthroplasty',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'Global Shoulder Arthroplasty',
        detailSubtitle: 'Third Party Congress',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        sideEventString: 'Check out our Side Event',
        tags: 'shoulder',
    },
    {
        ID: 7,
        type: 'content',
        date: new Date('2021-07-14'),
        endDate: new Date('2021-07-16'),
        time: '',
        boxTitle: '25th Congress of the European Association for Cranio Maxillo Facial Surgery (EACMFS)',
        boxSubtitle: 'Third Party Congress',
        detailTitle: '25th Congress of the European Association for Cranio Maxillo Facial Surgery (EACMFS)',
        detailSubtitle: 'Third Party Congress',
        sideEventString: 'Check out our Side Events',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        tags: 'cmf-and-thoracic',
    },
    {
        ID: 8,
        type: 'content',
        date: new Date('2021-09-10'),
        endDate: new Date('2021-09-11'),
        time: '',
        boxTitle: '14th Congress of the European Hip Society (EHS)',
        boxSubtitle: 'Third Party Congress',
        detailTitle: '14th Congress of the European Hip Society (EHS)',
        detailSubtitle: 'Third Party Congress',
        sideEventString: 'Check out our Side Events',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        tags: 'hip',
    },
    {
        ID: 9,
        type: 'content',
        date: new Date('2021-10-06'),
        endDate: new Date('2021-10-08'),
        time: '',
        boxTitle: 'EUROSPINE 2021',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'EUROSPINE 2021',
        detailSubtitle: 'Third Party Congress',
        sideEventString: 'Check out our Side Events',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        tags: 'spine',
    },
    {
        ID: 10,
        type: 'content',
        date: new Date('2021-10-14'),
        time: '',
        boxTitle: '35th EACTS Annual Meeting',
        boxSubtitle: 'Third Party Congress',
        boxDescription: 'Open from 14 to 16 October 2021',
        detailTitle: '35th EACTS Annual Meeting',
        detailSubtitle: 'Third Party Congress',
        detailLink: '/third-party-congresses',
        detailImage: 'third-party-congress-2021.jpg',
        sideEventString: 'Check out our Side Events',
        tags: 'cmf-and-thoracic',
    },
    {
        ID: 11,
        type: 'content',
        date: new Date('2022-04-27'),
        endDate: new Date('2022-04-29'),
        time: '',
        boxTitle: 'ESSKA Third Party Congress',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'ESSKA Third Party Congress',
        detailSubtitle: 'Third Party Congress',
        detailLink: '',
        detailImage: 'third-party-congress-2021.jpg',
        tags: '',
    },
    {
        ID: 12,
        type: 'content',
        date: new Date('2022-06-06'),
        endDate: new Date('2022-06-10'),
        time: '',
        boxTitle: 'IFSSH - IFSHT - FESSH Third Party Congress',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'IFSSH - IFSHT - FESSH Third Party Congress',
        detailSubtitle: 'Third Party Congress',
        detailLink: '',
        detailImage: 'third-party-congress-2021.jpg',
        tags: '',
    },
    {
        ID: 13,
        type: 'content',
        date: new Date('2022-06-22'),
        endDate: new Date('2022-06-24'),
        time: '',
        boxTitle: 'EFORT Third Party Congress',
        boxSubtitle: 'Third Party Congress',
        detailTitle: 'EFORT Third Party Congress',
        detailSubtitle: 'Third Party Congress',
        detailLink: '',
        detailImage: 'third-party-congress-2021.jpg',
        tags: '',
    },
    /* WEBINAR (IDs: 101 - 200) */
    {
        ID: 101,
        type: 'content',
        date: new Date('2021-02-04'),
        time: '',
        boxTitle: 'How does ROSA<sup>®</sup> Knee address different surgical techniques?',
        boxSubtitle: 'Webinar',
        boxDescription: 'S. Parratte &bull; F. Benazzo',
        detailTitle: 'How does ROSA<sup>®</sup> Knee address different surgical techniques?',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/how-does-rosa-knee-address-different-surgical-techniques',
        //detailLink: '<iframe id="ls_embed_1612531447" src="https://livestream.com/accounts/22298103/events/9517661/videos/217134084/player?width=640&height=360&enableInfo=false&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1612531447" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>',
        //iframe: '<iframe id="ls_embed_1612531447" src="https://livestream.com/accounts/22298103/events/9517661/videos/217134084/player?width=640&height=360&enableInfo=false&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1612531447" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>',
        detailImage: 'webinar-smart-2021.jpg',
        isWebinarHosted: true,
        registrationLink: '',
        live: true,
        program: "/assets/docs/webinars/webinar-rosa-knee-4-february-2021.pdf",
        tags: 'patient-pathway-optimization;robotics;knee',
    },
    /*
    {
        ID: 102,
        type: 'content',
        date: new Date('2021-02-11'),
        time: '',
        boxTitle: 'How is mymobility<sup>®</sup> helping care teams address resource constraints and remote patient care?',
        boxSubtitle: 'Webinar',
        boxDescription: 'O. Pearce',
        detailTitle: 'How is mymobility<sup>®</sup> helping care teams address resource constraints and remote patient care?',
        detailSubtitle: 'Webinar',
        detailLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        //registrationLink: 'https://us02web.zoom.us/webinar/register/WN_iI7MlDGlQCyyWw6NGCjitQ',
        live: true,
        program: "/assets/docs/webinars/webinar-mymobility-11-february-2021.pdf",
        tags: 'patient-pathway-optimization;knee',
    },
    */
    {
        ID: 103,
        type: 'content',
        date: new Date('2021-03-02'),
        time: '',
        boxTitle: 'Interprosthetic and Peri-Implant Fractures',
        boxSubtitle: 'Webinar',
        boxDescription: 'N. Kanakaris &bull; S. Mitchell &bull; S. Ruchholtz &bull; J. Tomas',
        detailTitle: 'Interprosthetic and Peri-Implant Fractures',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/interprosthetic-and-peri-implant-fractures',
        detailImage: 'webinar-smart-2021.jpg',
        isWebinarHosted: true,
        onDemand: true,
        program: '/assets/docs/webinars/webinar-interprosthetic-02-march-2021.pdf',
        tags: 'trauma;knee;hip',
    },
    {
        ID: 104,
        type: 'content',
        date: new Date('2021-03-04'),
        time: '',
        boxTitle: 'Simplifying revision knee surgery with zonal fixation',
        boxSubtitle: 'Webinar',
        boxDescription: 'A. Baldini &bull; P. Chapman-Sheath',
        detailTitle: 'Simplifying revision knee surgery with zonal fixation',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/simplifying-revision-knee-surgery-with-zonal-fixation',
        //iframe: '<iframe id="ls_embed_1615313211" src="https://livestream.com/accounts/22298103/events/9567235/videos/218531260/player?width=640&height=360&enableInfo=false&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1615313211" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>',
        //detailLink: '/on-demand-digital-learning-sessions/99/2821',
        detailImage: 'webinar-smart-2021.jpg',
        isWebinarHosted: true,
        // registrationLink: 'https://us02web.zoom.us/webinar/register/WN_mmQrbqSZSwqF3flTqL0V3w',
        live: true,
        program: '/assets/docs/webinars/webinar-simplifying-revision-knee-surgery-with-zonal-fixation.pdf',
        tags: 'knee',
    },
    {
        ID: 105,
        type: 'content',
        date: new Date('2021-03-18'),
        time: '',
        boxTitle: 'Hallux valgus: indications for surgical treatments and basic techniques',
        boxSubtitle: 'Webinar',
        boxDescription: 'P. Barouk &bull; C. Blundell',
        detailTitle: 'Hallux valgus: indications for surgical treatments and basic techniques',
        detailSubtitle: 'Webinar',
        isWebinarHosted: true,
        detailLink: '/webinars/hallux-valgus',
        //iframe: '<iframe id="ls_embed_1616496171" src="https://livestream.com/accounts/22298103/events/9589848/videos/219113655/player?width=640&height=360&enableInfo=false&defaultDrawer=&autoPlay=true&mute=false" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1616496171" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>',
        //detailLink: '/on-demand-digital-learning-sessions/104/4290',
        detailImage: 'webinar-smart-2021.jpg',
        //registrationLink: 'https://event.on24.com/wcc/r/2937147/425D1A888D391D7A46D8B6E17E373C3A',
        //registrationLink: 'https://us02web.zoom.us/webinar/register/WN_wNK3wi3SSCWSm0_Z19nvTA',
        live: true,
        program: "/assets/docs/webinars/webinar-hallux-valgus-18-march-2021.pdf",
        tags: 'foot-and-ankle',
    },
    {
        ID: 106,
        type: 'content',
        date: new Date('2021-03-23'),
        time: '',
        boxTitle: 'Sublaminar bands and Pedicle screws: Hybrid solutions for Pediatric deformity correction',
        boxSubtitle: 'Webinar',
        boxDescription: 'B. Ilharreborde &bull; M. Repko',
        detailTitle: 'Sublaminar bands and Pedicle screws: Hybrid solutions for Pediatric deformity correction',
        detailSubtitle: 'Webinar',
        isWebinarHosted: true,
        detailLink: '/webinars/sublaminar-bands-and-pedicle-screws',
        detailImage: 'webinar-smart-2021.jpg',
        onDemand: true,
        program: "/assets/docs/webinars/webinar-subliminar-bands-and-pedicle-screws-23-march-2021.pdf",
        tags: 'spine',
    },
    {
        ID: 107,
        type: 'content',
        date: new Date('2021-05-05'),
        time: '',
        boxTitle: 'Personalized TKR alignment: why and how to use surgery assisting technology',
        boxSubtitle: 'Webinar',
        boxDescription: 'M. Ganapathi &bull; S. Parratte &bull; N. Pradhan',
        detailTitle: 'Personalized TKR alignment: why and how to use surgery assisting technology',
        detailSubtitle: 'Webinar',
        isWebinarHosted: true,
        //detailLink: '/on-demand-digital-learning-sessions/99/4737',
        detailLink: '/webinars/personalized-tkr-alignment-why-and-how-to-use-surgery-assisting-technology',
        //iframe: '<iframe id="ls_embed_1620744202" src="https://livestream.com/accounts/22298103/events/9393937/videos/220991517/player?width=1920&height=1080&enableInfo=false&defaultDrawer=feed&autoPlay=false&mute=false" width="1920" height="1080" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1620744202" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>',
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        program: "/assets/docs/webinars/webinar-personalized-tkr-alignment-assisting-technologies-05-may-2021.pdf",
        tags: 'knee',
    },
    {
        ID: 108,
        type: 'content',
        date: new Date('2021-04-15'),
        time: '',
        boxTitle: 'How do technologies & patient pathway solutions deliver new clinical insights and a new customer experience?',
        boxSubtitle: 'Webinar',
        boxDescription: 'F. Benazzo',
        detailTitle: 'How do technologies & patient pathway solutions deliver new clinical insights and a new customer experience?',
        detailSubtitle: 'Webinar',
        isWebinarHosted: true,
        detailLink: '/webinars/how-do-technologies-and-patient-pathway-solutions-deliver-new-clinical-insights-and-a-new-customer-experience',
        detailImage: 'webinar-smart-2021.jpg',
        onDemand: true,
        program: "/assets/docs/webinars/webinar-zbedge-15-april-2021.pdf",
        tags: 'patient-pathway-optimization;knee',
    },
    {
        ID: 109,
        type: 'content',
        date: new Date('2021-05-06'),
        time: '',
        boxTitle: 'Implant of choice for Total Elbow Arthroplasty',
        boxSubtitle: 'Webinar',
        boxDescription: 'S. Vollans',
        detailTitle: 'Implant of choice for Total Elbow Arthroplasty',
        detailSubtitle: 'Webinar',
        isWebinarHosted: true,
        detailLink: '/webinars/implant-of-choice-for-total-elbow-arthroplasty',
        //detailLink: '/on-demand-digital-learning-sessions/106/4736',
        //iframe: '<iframe id="ls_embed_1620722893" src="https://livestream.com/accounts/22298103/events/9659099/videos/220863458/player?width=1920&height=1080&enableInfo=false&defaultDrawer=feed&autoPlay=false&mute=false" width="1920" height="1080" frameborder="0" scrolling="no" allowfullscreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1620722893" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>',
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        program: "/assets/docs/webinars/webinar-implant-of-choice-for-total-elbow-br-arthroplasty-thursday-06-may-2021.pdf",
        tags: 'elbow',
    },
    {
        ID: 110,
        type: 'content',
        date: new Date('2021-06-17'),
        time: '',
        boxTitle: 'Primary Hip Solutions_Episode 1: A solution for every primary hip',
        boxSubtitle: 'Webinar',
        boxDescription: 'P. Aldinger &bull; C. Perka &bull; S. Vehmeijer &bull; A. Troelsen',
        detailTitle: 'Primary Hip Solutions_Episode 1: A solution for every primary hip',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/primary-hip-solutions-episode-1-a-solution-for-every-primary-hip',
        detailImage: 'webinar-smart-2021.jpg',
        isWebinarHosted: true,
        // registrationLink: 'https://us02web.zoom.us/webinar/register/WN_ynFLuYEQRSuB8ZmZB_eP3g',
        live: true,
        program: "/assets/docs/webinars/webinar-primary-solutions-episode-1-A-solution-for-every-primary-hip-thursday-17th-june-2021.pdf",
        tags: 'hip',
    },
    {
        ID: 111,
        type: 'content',
        date: new Date('2021-05-14'),
        time: '',
        boxTitle: 'Complex Primary Hip Part 3: Femoral Neck Fracture',
        boxSubtitle: 'Webinar',
        boxDescription: 'R. El Attal &bull; S. Jones &bull; A. Rodriguez',
        detailTitle: 'Complex Primary Hip Part 3: Femoral Neck Fracture',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/complex-primary-hip-part-3',
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        program: "/assets/docs/webinars/webinar-complex-primary-hip-part-3_14-may-2021.pdf",
        tags: 'hip',
    },
    {
        ID: 112,
        type: 'content',
        date: new Date('2021-05-27'),
        time: '',
        boxTitle: 'Introduction to Zimmer Biomet TM™ Total Ankle Arthroplasty',
        boxSubtitle: 'Webinar',
        boxDescription: 'L. Schon &bull; F. Usuelli',
        detailTitle: 'Introduction to Zimmer Biomet TM™ Total Ankle Arthroplasty',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/introduction-to-zimmer-biomet-tm-total-ankle-arthroplasty',
        detailImage: 'webinar-smart-2021.jpg',
        isWebinarHosted: true,
        //registrationLink: 'https://us02web.zoom.us/webinar/register/WN_Hx-A8kiJR7qCU_nVz6wkJw',
        live: true,
        program: "/assets/docs/webinars/webinar-introduction-to-zimmer-biomet-tm-total-ankle-arthroplasty-27-may-2021.pdf",
        tags: 'foot-and-ankle',
    },
    {
        ID: 113,
        type: 'content',
        date: new Date('2021-06-01'),
        time: '',
        boxTitle: 'How are ROSA® Knee and OrthoIntel providing new insights for better clinical decision making?',
        boxSubtitle: 'Webinar',
        boxDescription: 'L. Vanlommel',
        detailTitle: 'How are ROSA® Knee and OrthoIntel providing new insights for better clinical decision making?',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/how-are-rosa-knee-and-orthoIntel-providing-new-insights-for-better-decision-making',
        detailImage: 'webinar-smart-2021.jpg',
        isWebinarHosted: true,
        //registrationLink: 'https://us02web.zoom.us/webinar/register/WN_i16aOkX2TiCdiG6FnrjMzg',
        live: true,
        program: "/assets/docs/webinars/webinar-how-are-rosa-knee-and-orthoIntel-providing-new-insights-for-better-decision-making-01-06-2021.pdf",
        tags: 'robotics;knee;patient-pathway-optimization',
    },
    {
        ID: 114,
        type: 'content',
        date: new Date('2021-06-09'),
        time: '',
        boxTitle: 'Why I use pre-op planning for every reverse shoulder arthroplasty',
        boxSubtitle: 'Webinar',
        boxDescription: 'A. Simons',
        detailTitle: 'Why I use pre-op planning for every reverse shoulder arthroplasty',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/pre-op-planning-for-every-reverse-shoulder-arthroplasty',
        isWebinarHosted: true,
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        program: "/assets/docs/webinars/webinar-why-i-use-pre-op-planning-for-every-reverse-shoulder-arthroplasty-wednesday-9th-june-2021.pdf",
        // registrationLink: 'https://us02web.zoom.us/webinar/register/WN_T5EBzMcBSeGnTdCLGCf4Lg',
        tags: 'shoulder',
    },
    {
        ID: 115,
        type: 'content',
        date: new Date('2021-06-10'),
        time: '',
        boxTitle: 'Three-part proximal humerus fractures: when to plate and when to nail',
        boxSubtitle: 'Webinar',
        boxDescription: 'N. Kanakaris',
        detailTitle: 'Three-part proximal humerus fractures: when to plate and when to nail',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/three-part-proximal-humerus-fractures:-when-to-plate-and-when-to-nail',
        isWebinarHosted: true,
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        program: "/assets/docs/webinars/three-part-proximal-humerus-fractures-when-to-plate-and-when-to-nail-june-10-2021.pdf",
        // registrationLink: 'https://us02web.zoom.us/webinar/register/WN_wnFOKW5UQrayTy4mez1PhQ',
        tags: 'trauma;shoulder',
    },
    {
        ID: 116,
        type: 'content',
        date: new Date('2021-07-01'),
        time: '',
        boxTitle: 'nSTRIDE APS in the orthopaedic knee practice',
        boxSubtitle: 'Webinar',
        boxDescription: 'M. Granrath &bull; E. Rosenlund',
        detailTitle: 'nSTRIDE APS in the orthopaedic knee practice',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/nstride-aps-in-the-orthopaedic-knee-practice',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        isWebinarHosted: true,
        program: "/assets/docs/webinars/webinar-nstride-aps-in-the-orthopaedicbr-knee-practice-thursday-1st-july-2021-email.pdf",
        tags: 'arthroscopy-and-biologics;knee',
    },
    {
        ID: 117,
        type: 'content',
        date: new Date('2021-07-12'),
        time: '',
        boxTitle: 'Complex Primary Hip Part 4 Hip-Spine Syndrome: Where are we today?',
        boxSubtitle: 'Webinar',
        boxDescription: 'S. Jones &bull; C. Perka &bull; A. Rodriguez',
        detailTitle: 'Complex Primary Hip Part 4 Hip-Spine Syndrome: Where are we today?',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/complex-primary-hip-part-4',
        detailImage: 'webinar-smart-2021.jpg',
        program: "/assets/docs/webinars/webinar-complex-primary-hip-part-4.pdf",
        isWebinarHosted: true,
        live: true,
        tags: 'hip',
    },
    {
        ID: 118,
        type: 'content',
        date: new Date('2021-09-01'),
        time: '',
        boxTitle: 'ZB4Residents Shoulder - LIVE Surgery with Hololens: surgical approach & principles of arthroplasty ',
        boxSubtitle: 'Webinar',
        boxDescription: 'O. Verborgt',
        detailTitle: 'ZB4Residents Shoulder - LIVE Surgery with Hololens: surgical approach & principles of arthroplasty ',
        detailSubtitle: 'Webinar',
        detailLink: '/zimmer-biomet-for-residents/zimmer-biomet-for-residents-shoulder-live-surgery-with-hololens',
        detailImage: 'zbfr-agenda-home.jpg',
        //registrationLink: 'https://cvent.me/2Kv2z5',
        program: "/assets/docs/webinars/webinar-live-surgery-with-hololens-2-surgical-approach-principles-of-shoulder-arthroplasty-1-september-2021.pdf",
        isWebinarHosted: true,
        live: true,
        tags: 'shoulder;residents',
    },
    {
        ID: 119,
        type: 'content',
        date: new Date('2021-09-23'),
        time: '',
        boxTitle: 'ZB4Residents: Rotator Cuff Repair',
        boxSubtitle: 'Webinar',
        boxDescription: 'M. Ferrand, C. Madail, O. Verborgt',
        detailTitle: 'ZB4Residents: Rotator Cuff Repair',
        detailSubtitle: 'Webinar',
        detailLink: '/zimmer-biomet-for-residents/zb4r-shoulder-rotator-cuff',
        detailImage: 'zbfr-agenda-home.jpg',
        // registrationLink: 'https://cvent.me/gREzrw',
        program: "/assets/docs/webinars/webinar-resident-&-fellow-educational-program.pdf",
        isWebinarHosted: true,
        live: true,
        tags: 'shoulder;residents;arthroscopy-and-biologics',
    },
    {
        ID: 120,
        type: 'content',
        date: new Date('2021-10-07'),
        time: '',
        boxTitle: 'ZB4Residents Hip - Instability of the hip: risk factors, stratification and treatment strategies',
        boxSubtitle: 'Webinar',
        boxDescription: 'R. Driesen, S. Jones, R. Hube',
        detailTitle: 'ZB4Residents Hip - Instability of the hip: risk factors, stratification and treatment strategies',
        detailSubtitle: 'Webinar',
        detailLink: '/zimmer-biomet-for-residents/zb4residents-hip-instability-of-the-hip-risk-factors-stratification-and-treatment-strategies',
        detailImage: 'zbfr-agenda-home.jpg',
        // registrationLink: 'https://cvent.me/vw2YMw',
        program: "/assets/docs/webinars/webinar-zb4residents-hip-instability-of-the-hip-risk-factors,-stratification-and-treatment-strategies.pdf",
        live: true,
        isWebinarHosted: true,
        tags: 'hip;residents',
    },
    // {
    //     ID: 121,
    //     type: 'content',
    //     date: new Date('2021-10-21'),
    //     time: '',
    //     boxTitle: 'ZB4Residents: Ankle Arthroscopy',
    //     boxSubtitle: 'Webinar',
    //     boxDescription: '',
    //     detailTitle: 'ZB4Residents: Ankle Arthroscopy',
    //     detailSubtitle: 'Webinar',
    //     detailLink: '',
    //     detailImage: 'zbfr-agenda-home.jpg',
    //     live: true,
    //     tags: 'foot-and-ankle;residents;arthroscopy-and-biologics',
    // },
    {
        ID: 122,
        type: 'content',
        date: new Date('2021-11-04'),
        time: '',
        boxTitle: 'ZB4Residents Knee - Unicompartmental OA: treatment options with HTO, UKA and TKA for the young and active',
        boxSubtitle: 'Webinar',
        boxDescription: 'M. Pietsch, R. Rossi, E. Thienpont',
        detailTitle: 'ZB4Residents Knee - Unicompartmental OA: treatment options with HTO, UKA and TKA for the young and active',
        detailSubtitle: 'Webinar',
        detailLink: '/zimmer-biomet-for-residents/zb4residents-knee-unicompartmental-oa',
        detailImage: 'zbfr-agenda-home.jpg',
        //registrationLink: 'https://cvent.me/L4radE',
        program: "/assets/docs/webinars/webinar-zb4residents-knee-unicompartmental-oa-treatment-options-with-hto-uka-and-tka-for-the-young-and-active.pdf",
        live: true,
        isWebinarHosted: true,
        tags: 'knee;residents',
    },
    {
        ID: 123,
        type: 'content',
        date: new Date('2021-11-18'),
        time: '',
        boxTitle: 'ZB4Residents Knee Arthroscopy - Knee injuries and treatment options',
        boxSubtitle: 'Webinar',
        boxDescription: 'A. Boutsiadis, C. Madail',
        detailTitle: 'ZB4Residents Knee Arthroscopy - Knee injuries and treatment options',
        detailSubtitle: 'Webinar',
        detailLink: '/zimmer-biomet-for-residents/zb4residents-knee-arthroscopy-knee-injuries-and-treatment-options',
        //registrationLink: 'https://web.cvent.com/event/4203031d-22e9-44da-a05c-21af256c6d71/regProcessStep1',
        detailImage: 'zbfr-agenda-home.jpg',
        program: "/assets/docs/webinars/webinar-zb4residents-knee-arthroscopy-knee-injuries-and-treatment-options.pdf",
        live: true,
        isWebinarHosted: true,
        tags: 'knee;residents;arthroscopy-and-biologics',
    },
    {
        ID: 124,
        type: 'content',
        date: new Date('2021-12-02'),
        time: '',
        boxTitle: 'ZB4Residents Trauma - Stabilisation of proximal femoral fractures: What are the challenges and solutions for optimum outcomes?',
        boxSubtitle: 'Webinar',
        boxDescription: 'N. Kanakaris, P. Giannoudis, S. Ruchholtz',
        detailTitle: 'ZB4Residents Trauma - Stabilisation of proximal femoral fractures: What are the challenges and solutions for optimum outcomes?',
        detailSubtitle: 'Webinar',
        detailLink: '/zimmer-biomet-for-residents/zb4residents-trauma-stabilisation-of-proximal-femoral-fractures',
        detailImage: 'zbfr-agenda-home.jpg',
        program: "/assets/docs/webinars/webinar-zb4residents-stabilisation-of-proximal-femoral-fractures-what-are-the-challenges-and-solutions-for-optimum-outcomes.pdf",
        // registrationLink: 'https://cvent.me/rqla5V',
        live: true,
        isWebinarHosted: true,
        tags: 'trauma;residents',
    },
    {
        ID: 125,
        type: 'content',
        date: new Date('2021-09-09'),
        time: '',
        boxTitle: 'ROSA® Knee user experience and clinical results',
        boxSubtitle: 'Webinar',
        boxDescription: 'J. Truijen &bull; S. Parratte',
        detailTitle: 'ROSA® Knee user experience and clinical results',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/rosa-knee-user-experience-and-clinical-results',
        detailImage: 'webinar-smart-2021.jpg',
        program: "/assets/docs/pages/webinar-program-9-sept.pdf",
        isWebinarHosted: true,
        live: true,
        tags: 'robotics;knee',
    },
    /*
    {
        ID: 126,
        type: 'content',
        date: new Date('2021-09-21'),
        time: '',
        boxTitle: 'Personalized cementless TKR: the benefits and best practice for long term biological fixation',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Personalized cementless TKR: the benefits and best practice for long term biological fixation',
        detailSubtitle: 'Webinar',
        detailLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        onDemand: true,
        tags: 'knee',
    },
    */
    {
        ID: 127,
        type: 'content',
        date: new Date('2021-10-13'),
        time: '',
        boxTitle: '10 deadly mistakes to avoid: infection after open fractures',
        boxSubtitle: 'Webinar',
        boxDescription: 'P. Giannoudis, S. Ruchholtz, J. Teixidor',
        detailTitle: '10 deadly mistakes to avoid infection after open fractures',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/ten-deadly-mistakes-to-avoid-infection-after-open-fractures',
        detailImage: 'webinar-smart-2021.jpg',
        program: "/assets/docs/webinars/webinar-10-deadly-mistakes-to-avoid-infection-after-open-fractures_13-10-2021.pdf",
        onDemand: true,
        isWebinarHosted: true,
        tags: 'cement-and-infection;trauma',
    },
    {
        ID: 128,
        type: 'content',
        date: new Date('2021-11-24'),
        time: '',
        boxTitle: 'Primary Solutions Episode 2: ASI and Outpatient surgery',
        boxSubtitle: 'Webinar',
        boxDescription: 'M. Raaijmaakers, M. Rudert, S. Vehmeijer',
        detailTitle: 'Primary Solutions_Episode 2 ASI and Outpatient surgery',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/primary-solutions-episode-2-asi-and-outpationt-surgery',
        detailImage: 'webinar-smart-2021.jpg',
        //registrationLink: 'https://cvent.me/RR1mav',
        program: "/assets/docs/webinars/webinar-primary-solutions-ep2-outpatient-surgery-wednesday-24th-november-2021.pdf",
        live: true,
        tags: 'hip',
        isWebinarHosted: true,
    },
    {
        ID: 129,
        type: 'content',
        date: new Date('2021-11-16'),
        time: '',
        boxTitle: 'Optimize your patient pathway with innovative technologies',
        boxSubtitle: 'Webinar',
        boxDescription: 'F. Benazzo, U. Nöth',
        detailTitle: 'Optimize your patient pathway with innovative technologies',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/optimize-your-patient-pathway-with-innovative-technologies',
        detailImage: 'webinar-smart-2021.jpg',
        // registrationLink: 'https://cvent.me/AgWvl8',
        program: "/assets/docs/webinars/webinar-optimize-your-patient-pathway-with-innovative-technologies-tuesday-16th-november-2021.pdf",
        live: true,
        isWebinarHosted: true,
        tags: 'hip;knee;patient-pathway-optimization',
    },
    // {
    //     ID: 130,
    //     type: 'content',
    //     date: new Date('2021-11-30'),
    //     time: '',
    //     boxTitle: 'Personalized knee balancing using the Persona Flexion First System',
    //     boxSubtitle: 'Webinar',
    //     boxDescription: '',
    //     detailTitle: 'Personalized knee balancing using the Persona Flexion First System',
    //     detailSubtitle: 'Webinar',
    //     detailLink: '',
    //     detailImage: 'webinar-smart-2021.jpg',
    //     onDemand: true,
    //     tags: 'knee',
    // },
    {
        ID: 131,
        type: 'content',
        date: new Date('2021-12-07'),
        time: '',
        boxTitle: 'First better then faster – How does a Rapid Recovery program lead to outpatient care?',
        boxSubtitle: 'Webinar',
        boxDescription: 'K. Gromov, S. Vehmeijer',
        detailTitle: 'First better then faster – How does a Rapid Recovery program lead to outpatient care?',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/first-better-than-faster-how-does-a-rapid-recovery-program-lead-to-outpatient-care',
        detailImage: 'webinar-smart-2021.jpg',
        program: "/assets/docs/webinars/webinar-zbedge-first-better-then-faster.pdf",
        onDemand: true,
        isWebinarHosted: true,
        tags: 'hip;knee;patient-pathway-optimization',
    },
    {
        ID: 134,
        type: 'content',
        date: new Date('2021-07-29'),
        time: '',
        boxTitle: 'How do diverse teams drive innovation in orthopedics and beyond?',
        boxSubtitle: 'Webinar',
        detailTitle: 'How do diverse teams drive innovation in orthopedics and beyond?',
        detailSubtitle: 'Webinar',
        boxDescription: 'D. Eygendaal & S. Parratte',
        detailLink: '/women-in-orthopedics/webinars/how-do-diverse-teams-drive-innovation-in-orthopedics-and-beyond',
        // registrationLink: 'https://us02web.zoom.us/webinar/register/WN_0xAFdlOcRg6hOveYvCy9dQ',
        detailImage: 'wio-agenda-home.jpg',
        isWebinarHosted: true,
        program: "/assets/docs/webinars/webinar-how-do-diverse-teams-drive-innovation-in-orthopedics-and-beyond.pdf",
        live: true,
        tags: 'women-in-orthopedics',
        wio: true,
    },
    {
        ID: 135,
        type: 'content',
        date: new Date('2021-08-26'),
        time: '',
        boxTitle: 'The value of mentorship in orthopedics and where to start',
        boxSubtitle: 'Webinar',
        detailTitle: 'The value of mentorship in orthopedics and where to start',
        detailSubtitle: 'Webinar',
        boxDescription: 'D. Dejour & R. Nasser',
        detailLink: '/women-in-orthopedics/webinars/the-value-of-mentorship-in-orthopedics-and-where-to-start',
        detailImage: 'wio-agenda-home.jpg',
        program: "/assets/docs/webinars/webinar-the-value-of-mentorship-in-orthopedics-and-where-to-start-26-august-2021.pdf",
        live: true,
        tags: 'women-in-orthopedics',
        isWebinarHosted: true,
        wio: true,
    },
    {
        ID: 136,
        type: 'content',
        date: new Date('2021-10-21'),
        time: '',
        boxTitle: 'What is the key to having a happy, balanced and successful career in Orthopedics?',
        boxSubtitle: 'Webinar',
        detailTitle: 'What is the key to having a happy, balanced and successful career in Orthopedics?',
        detailSubtitle: 'Webinar',
        boxDescription: '',
        detailLink: '/women-in-orthopedics/webinars/what-is-the-key-to-having-a-happy-balanced-and-successful-career-in-orthopedics',
        detailImage: 'wio-agenda-home.jpg',
        program: "/assets/docs/webinars/webinar-what-is-the-key-to-having-a-happy-balanced-and-successful-career-in-orthopedics-21-october-2021.pdf",
        boxDescription: 'H. Aguado, C. Madail',
        live: true,
        tags: 'women-in-orthopedics',
        isWebinarHosted: true,
        wio: true,
    },
    // {
    //     ID: 137,
    //     type: 'content',
    //     date: new Date('2021-10-01'),
    //     hideDate: true,
    //     time: '',
    //     boxTitle: 'Unconscious bias',
    //     boxSubtitle: 'Webinar',
    //     detailTitle: 'Unconscious bias',
    //     detailSubtitle: 'Webinar',
    //     boxDescription: '',
    //     detailLink: '',
    //     detailImage: 'wio-agenda-home.jpg',
    //     live: true,
    //     tags: 'women-in-orthopedics',
    //     wio: true,
    // },
    /*
    {
        ID: 138,
        type: 'content',
        date: new Date('2021-11-11'),
        time: '',
        boxTitle: 'Live workshop femoral revision + case discussion',
        boxSubtitle: 'Webinar',
        detailTitle: 'Live workshop femoral revision + case discussion',
        detailSubtitle: 'Webinar',
        boxDescription: '',
        detailLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        boxDescription: '',
        live: true,
        tags: 'hip',
    },
    {
        ID: 139,
        type: 'content',
        date: new Date('2021-11-18'),
        time: '',
        boxTitle: 'Live BioSkills acetabular revision + case discussion',
        boxSubtitle: 'Webinar',
        detailTitle: 'Live BioSkills acetabular revision + case discussion',
        detailSubtitle: 'Webinar',
        boxDescription: '',
        detailLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        boxDescription: '',
        live: true,
        tags: 'hip',
    },
    {
        ID: 140,
        type: 'content',
        date: new Date('2021-11-25'),
        time: '',
        boxTitle: 'Live case discussion on managing clinical problems',
        boxSubtitle: 'Webinar',
        detailTitle: 'Live case discussion on managing clinical problems',
        detailSubtitle: 'Webinar',
        boxDescription: '',
        detailLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        boxDescription: '',
        live: true,
        tags: 'hip',
    },
    */
    /* WEBINAR (IDs: 101 - 200) */
    {
        ID: 141,
        type: 'content',
        date: new Date('2022-02-16'),
        time: '',
        boxTitle: 'A solution for every hip with Avenir Complete',
        boxSubtitle: 'Webinar',
        boxDescription: 'B. Bloch, S. Boisgard, C. Perka, M. Raaijmaakers',
        detailTitle: 'A solution for every hip with Avenir Complete',
        detailSubtitle: 'Webinar',
        program: "/assets/docs/webinars/webinar-early-clinical-radiographical-outcomes-of-avenir-complete-wednesday-16th-february-2022.pdf",
        detailLink: '',
        //detailLink: '/webinars/avenir-complete-early-user',
        registrationLink: ShowRegistrationLink('2022-02-16', 'https://cvent.me/yGLVLN'),
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        tags: 'hip',
    },
    /*
    {
        ID: 142,
        type: 'content',
        date: new Date('2022-03-03'),
        time: '',
        boxTitle: 'Hybrid Sternal Closure',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Hybrid Sternal Closure',
        detailSubtitle: 'Webinar',
        detailLink: '',
        //detailLink: '/webinars/hybrid-sternal-closure',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        onDemand: true,
        isWebinarHosted: true,
        tags: 'cmf-and-thoracic',
    },
    */
    {
        ID: 143,
        type: 'content',
        date: new Date('2022-03-08'),
        time: '',
        boxTitle: 'International Women Day',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'International Women Day',
        detailSubtitle: 'Webinar',
        detailLink: '',
        //detailLink: '/women-in-orthopedics/webinars/iwd',
        registrationLink: '',
        detailImage: 'wio-agenda-home.jpg',
        live: true,
        wio: true,
        tags: 'women-in-orthopedics',
    },
    {
        ID: 144,
        type: 'content',
        date: new Date('2022-03-10'),
        time: '',
        boxTitle: 'How digital patient engagement Apps support fast track surgery?',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'How digital patient engagement Apps support fast track surgery?',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        tags: 'patient-pathway-optimization',
    },
    {
        ID: 145,
        type: 'content',
        date: new Date('2022-03-17'),
        time: '',
        boxTitle: 'TM Science for Young Consultants',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'TM Science for Young Consultants',
        detailSubtitle: 'Webinar',
        detailLink: '',
        //detailLink: '/webinars/tm-science-for-young-consultants',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        live: true,
        tags: 'hip',
    },
    {
        ID: 146,
        type: 'content',
        date: new Date('2022-03-24'),
        time: '',
        boxTitle: 'PJI Therapy',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'PJI Therapy',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'cement-and-infection',
        live: true,
    },
    {
        ID: 147,
        type: 'content',
        date: new Date('2022-03-31'),
        time: '',
        boxTitle: 'Complex Primary Hip Part 6',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Complex Primary Hip Part 6',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'hip',
        live: true,
    },
    {
        ID: 148,
        type: 'content',
        date: new Date('2022-04-07'),
        time: '',
        boxTitle: 'How pre-intra and post operative data provide new clinical insights?',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'How pre-intra and post operative data provide new clinical insights?',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'patient-pathway-optimization',
        live: true,
    },
    {
        ID: 149,
        type: 'content',
        date: new Date('2022-04-28'),
        time: '',
        boxTitle: 'Rib Fractures: to fix or not to fix? Advantages of rib fixation over conservative treatment',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Rib Fractures: to fix or not to fix? Advantages of rib fixation over conservative treatment',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'cmf-and-thoracic',
        live: true,
    },
    {
        ID: 150,
        type: 'content',
        date: new Date('2022-05-05'),
        time: '',
        boxTitle: 'Per-trochanteric hip fractures treatments and solutions ',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Per-trochanteric hip fractures treatments and solutions ',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'trauma;hip',
        live: true,
    },
    {
        ID: 151,
        type: 'content',
        date: new Date('2022-05-19'),
        time: '',
        boxTitle: 'Hot Topics in Total Elbow Arthroplasty',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Hot Topics in Total Elbow Arthroplasty',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'elbow',
        live: true,
    },
    {
        ID: 152,
        type: 'content',
        date: new Date('2022-05-26'),
        time: '',
        boxTitle: 'Fitmore 15yr Anniversary',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Fitmore 15yr Anniversary',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'hip',
        live: true,
    },
    {
        ID: 153,
        type: 'content',
        date: new Date('2022-06-02'),
        time: '',
        boxTitle: 'Sternal Closure - Controversies',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Sternal Closure - Controversies',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'cmf-and-thoracic',
        live: true,
    },
    {
        ID: 154,
        type: 'content',
        date: new Date('2022-06-09'),
        time: '',
        boxTitle: 'Zimmer Biomet data privacy principles: How we ensure hospital compliance?',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Zimmer Biomet data privacy principles: How we ensure hospital compliance?',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'patient-pathway-optimization',
        live: true,
    },
    {
        ID: 155,
        type: 'content',
        date: new Date('2022-06-16'),
        time: '',
        boxTitle: 'Avantage Design & Data Deep Dive',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Avantage Design & Data Deep Dive',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'hip',
        live: true,
    },
    {
        ID: 156,
        type: 'content',
        date: new Date('2022-06-23'),
        time: '',
        boxTitle: '10 Years of Personalized Knee Care',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: '10 Years of Personalized Knee Care',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'knee',
        live: true,
    },
    {
        ID: 157,
        type: 'content',
        date: new Date('2022-06-30'),
        time: '',
        boxTitle: 'Infection prevention with polytrauma patients',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Infection prevention with polytrauma patients',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: '',
        tags: 'trauma;cement-and-infection',
        live: true,
    },
    // {
    //     ID: 158,
    //     type: 'content',
    //     date: new Date('2022-07-14'),
    //     time: '',
    //     boxTitle: 'Rapid Recovery',
    //     boxSubtitle: 'Webinar',
    //     boxDescription: '',
    //     detailTitle: 'Rapid Recovery',
    //     detailSubtitle: 'Webinar',
    //     detailLink: '',
    //     registrationLink: '',
    //     detailImage: 'webinar-smart-2021.jpg',
    //     program: '',
    //     tags: 'trauma',
    //     live: true,
    // },
    {
        ID: 159,
        type: 'content',
        date: new Date('2022-07-19'),
        time: '',
        boxTitle: 'Women In Orthopedics',
        boxSubtitle: 'Webinar',
        boxDescription: '',
        detailTitle: 'Women In Orthopedics',
        detailSubtitle: 'Webinar',
        detailLink: '',
        registrationLink: '',
        detailImage: 'wio-agenda-home.jpg',
        program: '',
        tags: 'women-in-orthopedics',
        live: true,
        wio: true,
    },
    {
        ID: 160,
        type: 'content',
        date: new Date('2022-02-08'),
        time: '',
        boxTitle: 'Ligament respecting TKR with Persona® Flexion First Knee System',
        boxSubtitle: 'Webinar',
        boxDescription: 'D. Dejour, A. Halder',
        detailTitle: 'Ligament respecting TKR with Persona® Flexion First Knee System',
        detailSubtitle: 'Webinar',
        program: "/assets/docs/webinars/webinar-ligament-respecting-tkr-with-persona-ffi-system.pdf",
        detailLink: moment().format("YYYY-MM-DD") >= '2022-02-08' ?
            '/webinars/ligament-respecting-tkr-with-persona-flexion-first-knee-system'
            :
            '',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        onDemand: true,
        isWebinarHosted: true,
        tags: 'knee',
    },
    /* CASE FORUM (IDs: 201 - 300) */
    {
        ID: 201,
        type: 'content',
        date: new Date('2021-01-18'),
        endDate: new Date('2021-01-30'),
        time: '',
        boxTitle: 'Pre-operative planning for the challenging shoulder arthroplasty',
        boxSubtitle: 'Case forum',
        boxDescription: 'O. Verborgt',
        detailTitle: 'Pre-operative planning for the challenging shoulder arthroplasty',
        detailSubtitle: 'Case forum',
        detailLink: '',
        //detailLink: '/case-forum-pre-operative-planning-for-the-challenging-shoulder-arthroplasty',
        detailImage: 'case-forum-2021-new.jpg',
        //calendar: 'https://www.addevent.com/event/du5763926+outlook',
        tags: 'shoulder',
    },
    {
        ID: 202,
        type: 'content',
        date: new Date('2021-02-15'),
        endDate: new Date('2021-02-27'),
        time: '',
        boxTitle: 'Rotator cuff injuries and repair options',
        boxSubtitle: 'Case forum',
        boxDescription: 'M. Ferrand',
        detailTitle: 'Rotator cuff injuries and repair options',
        detailSubtitle: 'Case forum',
        detailLink: moment().format("YYYY-MM-DD") <= moment(new Date('2021-02-27')).format("YYYY-MM-DD") ? '/case-forum-rotator-cuff' : '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-02-27')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/Ug5763948+outlook' : '',
        tags: 'shoulder;arthroscopy-and-biologics',
    },
    {
        ID: 203,
        type: 'content',
        date: new Date('2021-03-15'),
        endDate: new Date('2021-03-27'),
        time: '',
        boxTitle: 'How can I simplify this knee revision?',
        boxSubtitle: 'Case forum',
        boxDescription: 'P. Chapman-Sheath',
        detailTitle: 'How can I simplify this knee revision?',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-03-01')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-03-27')).format("YYYY-MM-DD") ?
                '/case-forum-knee-revision' :
                '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-03-27')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/GP5763952+outlook' : '',
        tags: 'knee',
    },
    {
        ID: 204,
        type: 'content',
        date: new Date('2021-03-29'),
        endDate: new Date('2021-04-10'),
        time: '',
        boxTitle: 'Interprosthetic and peri-implant fractures',
        boxSubtitle: 'Case forum',
        boxDescription: 'S. Mitchell',
        detailTitle: 'Interprosthetic and peri-implant fractures',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-03-16')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-04-10')).format("YYYY-MM-DD") ?
                '/case-forum-interprosthetic-and-peri-implant-fractures' :
                '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-04-10')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/mN6037899+outlook' : '',
        tags: 'trauma;hip;knee',
    },
    {
        ID: 205,
        type: 'content',
        date: new Date('2021-04-12'),
        endDate: new Date('2021-04-24'),
        time: '',
        boxTitle: 'Planning Temporomandibular Joint Replacement: Stock vs Custom procedures',
        boxSubtitle: 'Case forum',
        boxDescription: 'N. Saeed',
        detailTitle: 'Planning Temporomandibular Joint Replacement: Stock vs Custom procedures',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-03-29')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-04-24')).format("YYYY-MM-DD") ?
                '/case-forum-planning-temporomandibular-joint-replacement' :
                '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-04-24')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/uZ6037901+outlook' : '',
        tags: 'cmf-and-thoracic',
    },
    {
        ID: 206,
        type: 'content',
        date: new Date('2021-05-17'),
        endDate: new Date('2021-05-29'),
        time: '',
        boxTitle: 'Personalized TKR alignment: turning theory into practice',
        boxSubtitle: 'Case forum',
        boxDescription: 'M. Ganapathi',
        detailTitle: 'Personalized TKR alignment: turning theory into practice',
        detailSubtitle: 'Case forum',
        detailLink: '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-05-29')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/Yu5763956+outlook' : '',
        tags: 'knee',
    },
    {
        ID: 207,
        type: 'content',
        date: new Date('2021-06-14'),
        endDate: new Date('2021-06-26'),
        time: '',
        boxTitle: 'Pre-op planning for every reverse shoulder arthroplasty',
        boxSubtitle: 'Case forum',
        boxDescription: 'A. Simons',
        detailTitle: 'Pre-op planning for every reverse shoulder arthroplasty',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-06-03')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-06-26')).format("YYYY-MM-DD") ?
                '/case-forum-pre-op-planning-for-every-reverse-shoulder-arthroplasty' :
                '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-06-26')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/PT5763984+outlook' : '',
        tags: 'shoulder',
    },
    // {
    //     ID: 208,
    //     type: 'content',
    //     date: new Date('2021-10-18'),
    //     endDate: new Date('2021-10-23'),
    //     time: '',
    //     boxTitle: 'Tether',
    //     boxSubtitle: 'Case forum',
    //     boxDescription: '',
    //     detailTitle: 'Tether',
    //     detailSubtitle: 'Case forum',
    //     detailLink: '',
    //     detailImage: 'case-forum-2021-new.jpg',
    //     calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-10-23')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/Sg6380907+outlook' : '',
    //     tags: 'spine',
    // },
    {
        ID: 209,
        type: 'content',
        date: new Date('2021-10-25'),
        endDate: new Date('2021-10-30'),
        time: '',
        boxTitle: 'Infection prevention with polytrauma patients',
        boxSubtitle: 'Case forum',
        boxDescription: 'P. Giannoudis',
        detailTitle: 'Infection prevention with polytrauma patients',
        detailSubtitle: 'Case forum',
        detailLink: moment().format("YYYY-MM-DD") >= moment(new Date('2021-10-15')).format("YYYY-MM-DD") &&
            moment().format("YYYY-MM-DD") <= moment(new Date('2021-10-30')).format("YYYY-MM-DD") ?
            '/case-forum-infection-prevention-with-polytrauma-patients' :
            '',
        detailImage: 'case-forum-2021-new.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-10-30')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/WQ6380971+outlook' : '',
        tags: 'trauma;cement-and-infection',
    },
    {
        ID: 210,
        type: 'content',
        date: new Date('2021-09-06'),
        endDate: new Date('2021-09-11'),
        time: '',
        boxTitle: 'ZB4Residents Shoulder - Surgical approach & principles of arthroplasty',
        boxSubtitle: 'Case forum',
        boxDescription: 'O. Verborgt',
        detailTitle: 'ZB4Residents Shoulder - Surgical approach & principles of arthroplasty',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-08-23')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-09-11')).format("YYYY-MM-DD") ?
                '/case-forum-zb4residents-shoulder-surgical-approach-and-principles-of-arthroplasty' :
                '',
        detailImage: 'zbfr-agenda-home.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-09-11')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/gb6380891+outlook' : '',
        tags: 'shoulder;residents',
    },
    {
        ID: 211,
        type: 'content',
        date: new Date('2021-10-11'),
        endDate: new Date('2021-10-16'),
        time: '',
        boxTitle: 'ZB4Residents Hip - Instability of the hip: risk factors, stratification and treatment strategies',
        boxSubtitle: 'Case forum',
        boxDescription: 'R. Hube',
        detailTitle: 'ZB4Residents Hip - Instability of the hip: risk factors, stratification and treatment strategies',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-09-27')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-10-16')).format("YYYY-MM-DD") ?
                '/case-forum-zb4residents-hip-instability-of-the-hip-risk-factors-stratification-and-treatment-strategies' :
                '',
        detailImage: 'zbfr-agenda-home.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-10-16')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/bS6380895+outlook' : '',
        tags: 'hip;residents',
    },
    {
        ID: 212,
        type: 'content',
        date: new Date('2021-11-08'),
        endDate: new Date('2021-11-13'),
        time: '',
        boxTitle: 'ZB4Residents Knee - Unicompartmental OA: treatment options with HTO, UKA and TKA for the young and active',
        boxSubtitle: 'Case forum',
        boxDescription: 'E. Thienpont',
        detailTitle: 'ZB4Residents Knee - Unicompartmental OA: treatment options with HTO, UKA and TKA for the young and active',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-11-08')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-11-13')).format("YYYY-MM-DD") ?
                '/case-forum-zb4residents-knee-unicompartimental-oa-treatment-options-with-hto-uka-and-tka-for-the-young-and-active' :
                '',
        detailImage: 'zbfr-agenda-home.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-11-13')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/BN6380915+outlook' : '',
        tags: 'knee;residents',
    },
    {
        ID: 213,
        type: 'content',
        date: new Date('2021-11-22'),
        endDate: new Date('2021-11-27'),
        time: '',
        boxTitle: 'ZB4Residents Knee Arthroscopy - Knee injuries and treatment options',
        boxSubtitle: 'Case forum',
        boxDescription: 'J. Waite',
        detailTitle: 'ZB4Residents: Knee Arthroscopy',
        detailSubtitle: 'Case forum',
        detailLink: '',
        detailImage: 'zbfr-agenda-home.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-11-27')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/Lh6380919+outlook' : '',
        tags: 'knee;residents;arthroscopy-and-biologics',
    },
    {
        ID: 214,
        type: 'content',
        date: new Date('2021-12-06'),
        endDate: new Date('2021-12-11'),
        time: '',
        boxTitle: 'ZB4Residents Trauma - Stabilisation of proximal femoral fractures: What are the challenges and solutions for optimum outcomes?',
        boxSubtitle: 'Case forum',
        boxDescription: 'P. Giannoudis',
        detailTitle: 'ZB4Residents Trauma - Stabilisation of proximal femoral fractures: What are the challenges and solutions for optimum outcomes?',
        detailSubtitle: 'Case forum',
        detailLink:
            moment().format("YYYY-MM-DD") >= moment(new Date('2021-12-01')).format("YYYY-MM-DD") &&
                moment().format("YYYY-MM-DD") <= moment(new Date('2021-12-11')).format("YYYY-MM-DD") ?
                '/case-forum-zb4residents-trauma-stabilisation-of-proximal-femoral-fractures' :
                '',
        detailImage: 'zbfr-agenda-home.jpg',
        calendar: moment().format("YYYY-MM-DD") <= moment(new Date('2021-12-11')).format("YYYY-MM-DD") ? 'https://www.addevent.com/event/Wc6380923+outlook' : '',
        tags: 'trauma;residents',
    },
    {
        ID: 215,
        type: 'content',
        date: new Date('2021-10-28'),
        time: '',
        boxTitle: 'How is ROSA® Knee affecting episode-of-care efficiency?',
        boxSubtitle: 'Webinar',
        boxDescription: 'S. Parratte, D. Hannouche',
        detailTitle: 'How is ROSA® Knee affecting episode-of-care efficiency?',
        detailSubtitle: 'Webinar',
        detailLink: '/webinars/how-is-rosa-knee-affecting-episode-of-care-efficiency',
        // registrationLink: 'https://urldefense.com/v3/__https:/cvent.me/v7vDZR__;!!AliVsMdY0FmBEA!JxqWD5IJYpCAdCu-4MaPp2UOgotFi5zeaP6SbO2UlXKLkPHC46WKaIxpPk_rRSu_SA3Z8A$',
        registrationLink: '',
        detailImage: 'webinar-smart-2021.jpg',
        program: "/assets/docs/webinars/webinar-how-is-rosareg-knee-affecting-episode-of-care-efficiency-thursday-28th-october-program.pdf",
        live: true,
        isWebinarHosted: true,
        tags: 'robotics;knee',
    },
    {
        ID: 216,
        type: 'content',
        date: new Date('2022-02-14'),
        endDate: new Date('2022-02-26'),
        time: '',
        boxTitle: 'Shoulder arthroplasty pre-operative planning',
        boxSubtitle: 'Case forum',
        boxDescription: 'A.Simons',
        detailTitle: 'Shoulder arthroplasty pre-operative planning',
        detailSubtitle: 'Case forum',
        detailLink: ShowDetailLink(new Date('2022-01-31'), new Date('2022-02-26'), "/case-forum-shoulder-arthroplastiy-pre-operative-planning-2022"),
        detailImage: 'case-forum-2021-new.jpg',
        calendar: ShowAddToCalendar(new Date('2022-02-14'), "https://www.addevent.com/event/MT10670451+outlook"),
        tags: 'shoulder',
    },
    {
        ID: 217,
        type: 'content',
        date: new Date('2022-05-09'),
        endDate: new Date('2022-05-21'),
        time: '',
        boxTitle: 'Per-trochanteric hip fractures treatments and solutions ',
        boxSubtitle: 'Case forum',
        boxDescription: '',
        detailTitle: 'Per-trochanteric hip fractures treatments and solutions ',
        detailSubtitle: 'Case forum',
        detailLink: ShowDetailLink(new Date('2022-04-25'), new Date('2022-05-21'), "/case-forum-hip-fractures-2022"),
        detailImage: 'case-forum-2021-new.jpg',
        calendar: ShowAddToCalendar(new Date('2022-05-09'), 'https://www.addevent.com/event/Bi10670465+outlook'),
        tags: 'trauma;hip',
    },
    {
        ID: 218,
        type: 'content',
        date: new Date('2022-07-04'),
        endDate: new Date('2022-07-16'),
        time: '',
        boxTitle: 'Infection prevention with polytrauma patients',
        boxSubtitle: 'Case forum',
        boxDescription: '',
        detailTitle: ' Infection prevention with polytrauma patients',
        detailSubtitle: 'Case forum',
        detailLink: ShowDetailLink(new Date('2022-06-20'), new Date('2022-07-16'), "/case-forum-trauma-infection-prevention-with-polytrauma-patients-2022"),
        detailImage: 'case-forum-2021-new.jpg',
        calendar: ShowAddToCalendar(new Date('2022-07-04'), 'https://www.addevent.com/event/od10708777+outlook'),
        tags: 'trauma;cement-and-infection',
    },
];

let pjiLink = '/pji-series';
switch (true) {
    case new Date() >= new Date('2020-03-19') && new Date() < new Date('2020-03-26'):
        pjiLink = '/pji-series/pji-series-prevention-1'
        break;
    case new Date() >= new Date('2020-03-26') && new Date() < new Date('2020-04-02'):
        pjiLink = '/pji-series/pji-series-prevention-2'
        break;
    case new Date() >= new Date('2020-04-02') && new Date() < new Date('2020-04-09'):
        pjiLink = '/pji-series/pji-series-diagnosis-1'
        break;
    case new Date() >= new Date('2020-04-09') && new Date() < new Date('2020-04-16'):
        pjiLink = '/pji-series/pji-series-diagnosis-2'
        break;
    case new Date() >= new Date('2020-04-16') && new Date() < new Date('2020-04-23'):
        pjiLink = '/pji-series/pji-series-treatment-1'
        break;
    case new Date() >= new Date('2020-04-23'):
        pjiLink = '/pji-series/pji-series-treatment-2'
        break;
    default:
        pjiLink = '/pji-series'
}

let caseForumLink = '';
const dateNow = new Date();
switch (true) {
    case dateNow >= new Date('2020-05-18') && dateNow < new Date('2020-05-31'):
        caseForumLink = '/case-forum-how-decide-between-uka-tka'
        break;
    case dateNow >= new Date('2020-06-01') && dateNow < new Date('2020-06-07'):
        caseForumLink = '/case-forum-oxford-cementless-pkr'
        break;
    case dateNow >= new Date('2020-06-08') && dateNow < new Date('2020-06-21'):
        caseForumLink = '/case-forum-trabecular-metal-total-ankle'
        break;
    case dateNow >= new Date('2020-09-14') && dateNow < new Date('2020-09-27'):
        caseForumLink = '/case-forum-cervical-arthroplasty'
        break;
    case dateNow >= new Date('2020-10-05') && dateNow < new Date('2020-10-18'):
        caseForumLink = '/case-forum-complex-cases-in-primary-tka'
        break;
    case dateNow >= new Date('2020-11-09') && dateNow < new Date('2020-11-22'):
        caseForumLink = '/case-forum-superior-capsular-reconstruction'
        break;
    case dateNow >= new Date('2020-12-07') && dateNow < new Date('2020-12-20'):
        caseForumLink = '/case-forum-proximal-humeral-fractures'
        break;
    default:
        caseForumLink = ''
}

function ShowDetailLink(startDate, endDate, link) {
    let detailLink = "";
    if (moment().format("YYYY-MM-DD") >= moment(startDate).add(-14, 'days').format("YYYY-MM-DD") &&
        moment().format("YYYY-MM-DD") <= moment(endDate).format("YYYY-MM-DD")) {
        detailLink = link;
    }
    return detailLink;
}

function ShowAddToCalendar(date, addToCalendarLink) {
    if (moment(date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
        return '';
    } else {
        return addToCalendarLink;
    }
}

function ShowRegistrationLink(endDate, link) {
    let registrationLink = "";
    if (moment().format("YYYY-MM-DD") <= moment(endDate).format("YYYY-MM-DD")) {
        registrationLink = link;
    }
    return registrationLink;
}